@import "./styles/global/loader";
@import "./styles/helpers";

#root {
  height: 100%;
}

body, html {
  height: 100%;
  background-color: white !important;
}

.missedData {
  width: 10px;
  height: 10px;
  background: red;
  display: inline-block;
}

._pendo-badge, ._pendo-badge_ {
  z-index: 1299 !important;
}
