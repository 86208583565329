.--text-center {
  text-align: center !important;
}

.--text-right {
  text-align: right !important;
}

.--text-left {
  text-align: left !important;
}

.--uppercase {
  text-transform: uppercase !important;
}

.--text-18 {
  font-size: 18px !important;
}

.--text-16 {
  font-size: 16px !important;
}

.--text-14 {
  font-size: 14px !important;
}

.--text-12 {
  font-size: 12px !important;
}

.--text-10 {
  font-size: 10px !important;
}

.--text-h1-size {
  font-size: 28px !important;
}

.--text-semi-bold {
  font-weight: 500 !important;
}

.--green-text {
  color: #289e40; // theme.palette.general.priceGreen
}

.--primary-color {
  color: #b96211 !important;
}

.--white-color {
  color: #fff !important;
}

.--black-color {
  color: #000 !important;
}

.--full-height {
  height: 100% !important;
}

.--full-width {
  width: 100% !important;
}

.--capitalize {
  text-transform: capitalize !important;
}

.--font-100 {
  font-weight: 100 !important;
}

.--font-300 {
  font-weight: 300 !important;
}

.--font-400 {
  font-weight: 400 !important;
}

.--font-500 {
  font-weight: 500 !important;
}

.--font-600 {
  font-weight: 600 !important;
}

.--font-900 {
  font-weight: 900 !important;
}

.--word-break {
  word-break: break-all !important;
}
